<!--
 * @Description: 登录页 用户第一次登录绑定手机号 login
 * @Author: yanxiao
 * @Github: https://github.com/yanxiaos
 * @Date: 2021-07-05 19:56:40
 * @LastEditors: yanxiao
-->

<!--  -->
<template>
  <div class=''>
    <van-nav-bar title="绑定手机号" />
    <div>
      <van-field v-model="sendPinForm.phoneNum"
                 label="手机号"
                 placeholder="请输入手机号" />
      <van-field v-model="confirmForm.pinNumber"
                 center
                 label="短信验证码"
                 placeholder="请输入短信验证码">
        <template #button>
          <van-button size="small"
                      type="primary"
                      :disabled="pinButDisabled"
                      @click="seedPin">{{pinButText}}</van-button>
        </template>
      </van-field>
    </div>
    <div class="confirmBut-box">
      <div class="confirmBut"
           @click="confirm">确定</div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      sendPinForm: {
        phoneNum: ''
      },
      confirmForm: {
        openId: this.$route.query.openId,
        phoneNum: '',
        pinNumber: '',
        type: 'officialAccount'
      },
      confirmPhoneNum: '',
      pinButDisabled: false,
      pinButText: '发送验证码',
      second: 60
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    seedPin () {
      const phone = this.sendPinForm.phoneNum
      const regex = /^(13[0-9]{9})|(15[0-9]{9})|(17[0-9]{9})|(18[0-9]{9})|(19[0-9]{9})$/
      if (!regex.test(phone)) {
        this.$toast('请输入正确的手机号')
        return false
      }
      // 发送按钮禁用
      this.pinButDisabled = true
      console.log('发送验证码', this.sendPinForm)
      this.$selfInfo.sendSms(this.sendPinForm).then(() => {
        this.$toast('发送成功')
        this.confirmForm.phoneNum = this.sendPinForm.phoneNum
        this.pinButText = `重新发送 ${this.second}s`
        this.setButTime()
      }).catch(() => {
        this.$toast.fail('发送失败')
        this.pinButDisabled = false
      })
    },
    confirm () {
      if (!this.confirmForm.pinNumber && !this.confirmForm.phoneNum) {
        this.$toast('请输入手机号或验证码')
        return false
      }
      console.log('发送绑定请求', this.confirmForm)
      this.$selfInfo.bindWxUser(this.confirmForm).then(res => {
        console.log('绑定返回', res)
        const userInfo = JSON.stringify(res.resultEntity)
        sessionStorage.setItem('userInfo', userInfo)

        if (res.resultCode === '3001') {
          this.$toast.fail('绑定失败！手机号已被注册')
          return
        }

        this.$dialog.alert({
          message: '绑定成功'
        }).then(() => {
          // 绑定成功后退出到公众号
          if (window.WeixinJSBridge) {
            window.WeixinJSBridge.call('closeWindow') // 关闭当前页面返回到公众号
          }
        })
        // location.replace(
        //   'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxaa4bd9cc425e1619&redirect_uri=https://esalotto.cn/cq-parking-tencent/%23/' + this.$route.query.page + '&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect'
        // )
        // this.$router.replace({
        //   path: '/',
        //   query: { page: this.$route.query.page }
        // })
      })
    },
    setButTime () {
      const ctrl = setInterval(() => {
        this.second--
        this.pinButText = `重新发送 ${this.second}s`
        if (this.second <= 0) {
          clearInterval(ctrl)
          this.pinButDisabled = false
          this.pinButText = '发送验证码'
        }
      }, 1000)
    }
  }
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.confirmBut-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  margin-top: 30px;
  .confirmBut {
    width: 94%;
    height: 100%;
    background: #19a9fc;
    border-radius: 5px;
    font-size: 17px;
    line-height: 40px;
    font-weight: Regular;
    text-align: center;
    color: #ffffff;
    &:active {
      background: #45bbff;
    }
  }
}
</style>
